
































































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetDocumentosCategorias,
  dispatchGetDocumentos,
  dispatchUpdateDocumento,
  dispatchGetDocumentosTipos,
} from '@/store/admin/actions';
import {
  readAdminOneDocumento,
  readAdminDocumentoCategorias,
  readAdminDocumentoTipos,
} from '@/store/admin/getters';
import { apiUploadFolder } from '@/env';

@Component
export default class EditDocumento extends Vue {
  public valid = true;
  public nombre: string = '';
  public descripcion: string = '';
  public categoria: number = 0;
  public categoriasall: any = [];
  public tipo: number = 0;
  public tiposall: any = [];
  public urlDoc: string = '';
  public urlTipo: string = '';
  public file: any = null;
  public async mounted() {
    await dispatchGetDocumentosCategorias(this.$store);
    await dispatchGetDocumentosTipos(this.$store);
    await dispatchGetDocumentos(this.$store);

    this.reset();
  }

  public reset() {
    if (this.documento) {
      this.nombre = this.documento.nombre;
      this.descripcion = this.documento.descripcion;
      this.categoria = this.documento.id_categoria;
      this.tipo = this.documento.id_tipo;
      this.urlDoc = this.documento.url;
      this.urlTipo = this.documento.tipo.tipo;
    }
    if (this.categorias) {
      this.categoriasall = this.categorias;
    }
    if (this.tipos) {
      this.tiposall = this.tipos;
    }
  }
  public url(u) {
    return apiUploadFolder + u;
  }
  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile = new FormData();
      if (this.nombre) {
        updatedProfile.append('nombre', this.nombre);
      }
      if (this.descripcion) {
        updatedProfile.append('descripcion', this.descripcion);
      }

      updatedProfile.append('id_categoria', String(this.categoria));
      updatedProfile.append('id_tipo', String(this.tipo));
      if (this.file) {
        updatedProfile.append('file', this.file);
      }
      await dispatchUpdateDocumento(this.$store, {
        id: Number(this.$router.currentRoute.params.id),
        documento: updatedProfile,
      });
      this.$router.push('/main/admin/documentos/all');
    }
  }

  get documento() {
    return readAdminOneDocumento(this.$store)(
      +this.$router.currentRoute.params.id,
    );
  }
  get categorias() {
    return readAdminDocumentoCategorias(this.$store);
  }
  get tipos() {
    return readAdminDocumentoTipos(this.$store);
  }
}
